// TODO: Consider using TypedJSON to perform deserialization and serialization

export type ChartPValueType = Record<string, Record<string, number>>;

export type ChartDataType = Record<string, number[]>;

export type GroupMapType = Record<string, string[]>;

export class PopulationStatistics {
    points: ChartDataType;
    statistics: ChartDataType;
    pvalues: ChartPValueType;

    constructor(data: any) {
        this.points = data?.points ?? {};
        this.statistics = data?.statistics ?? {};
        this.pvalues = data?.pvalues ?? {};
    }
}

export class Report {
    filename: string;
    has_data: boolean;

    samples: string[];

    group_order: string[];
    groups: GroupMapType;

    populations: string[];

    constructor(data: any) {
        this.filename = data?.filename ?? '';
        this.has_data = data?.has_data ?? false;
        this.samples = data?.samples ?? [];
        this.group_order = data?.group_order ?? [];
        this.groups = data?.groups ?? {};
        this.populations = data?.populations ?? [];
    }

    // apiGroup.GET("/report/create", handler.CreateReport)
    static async getReport(): Promise<Report> {
        const data = (window as any).getReport();
        console.log("Create Report: " + data);
        return new Report(JSON.parse(data));
    }

    // apiGroup.GET("/report/create", handler.CreateReport)
    static async createReport(): Promise<Report> {
        const data = (window as any).resetReport();
        console.log("Create Report: " + data);
        return new Report(JSON.parse(data));
    }

    // apiGroup.POST("/report/:id", handler.UpdateReport)
    static async uploadFileToReport(file: File, progressCallback?: (current: number, total: number) => void): Promise<Report | null> {

        return new Promise( (resolve, reject) => {

            const fr = new FileReader();
            fr.onprogress = (event) => {
                if (progressCallback) {
                    progressCallback(event.loaded, event.total);
                }
            }
            fr.onerror = () => {
                console.log("Failed to load file for some reason.");
                reject("")
                if (progressCallback) {
                    progressCallback(-1, 0);
                }
            }
            fr.onload = () => {
                console.log("Loaded file data: " + file.name);
                let result = (window as any).uploadFileToReport(
                    file.name,
                    fr.result
                );

                console.log("Report data: " + result);

                if (result === "") {
                    reject("Failed to load");
                    if (progressCallback) {
                        progressCallback(-1, 0);
                    }
                    return;
                }

                try {
                    resolve(new Report(JSON.parse(result)));
                    if (progressCallback) {
                        progressCallback(100, 100);
                    }
                } catch {
                    if (progressCallback) {
                        progressCallback(-1, 0);
                    }
                }
            };
            fr.readAsArrayBuffer(file);
        })
    }
    // apiGroup.DELETE("/reports/:id", handler.DeleteReport)

    // apiGroup.GET("/reports/:id/samples", handler.GetSamples)

    // apiGroup.GET("/reports/:id/groups", handler.GetGroups)
    // apiGroup.PUT("/reports/:id/groups/:group", handler.CreateGroup)
    static async createGroup(group: string): Promise<Report> {
        let json = (window as any).createGroup(group);

        return new Report(JSON.parse(json));
    }
    // apiGroup.PATCH("/reports/:id/groups/:group", handler.UpdateGroup)
    static async addSampleToGroup(group: string, sample: string): Promise<Report> {
        let json = (window as any).addSampleToGroup(group, sample);
        return new Report(JSON.parse(json));
    }
    // apiGroup.PATCH("/reports/:id/groups/:group", handler.UpdateGroup)
    static async removeSampleFromGroup(group: string, sample: string): Promise<Report> {
        let json = (window as any).removeSampleFromGroup(group, sample);
        return new Report(JSON.parse(json));
    }
    // apiGroup.DELETE("/reports/:id/groups/:group", handler.DeleteGroup)
    static async deleteGroup(group: string): Promise<Report> {
        return new Report({});
    }

    // apiGroup.GET("/reports/:id/populations", handler.GetPopulations)
    // apiGroup.GET("/reports/:id/populations/data", handler.GetAllData)
    static async getAllData(): Promise<void> {
        const data = (window as any).getAllData();

        if (data) {
            try {
                const handle = await (window as any).showSaveFilePicker({
                    suggestedName: "samples.zip",
                    types: [{
                        description: 'Exported sample data',
                        accept: {
                            'application/octet-stream': ['.zip']
                        }
                    }]
                });
    
                const writable = await handle.createWritable();
    
                await writable.write(data);
                await writable.close();
            } catch {
                console.log("Stopped file save");
            }
        }
    }

    // apiGroup.GET("/reports/:id/populations/:population/chart", handler.GetPopulationChart)
    static async getChartData(population: string): Promise<PopulationStatistics> {
        const data = (window as any).getPopulationChartData(population);
        
        console.log("Create Pop Data: " + data);
        if (data && data !== "") {
            return new PopulationStatistics(JSON.parse(data));
        } else {
            
            return new PopulationStatistics({});
        }
    }
}
