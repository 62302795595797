
import { Report } from '../api/Report';

export interface IReportExporterProps {
    counter: number;
    exportEnable: boolean;
}

const ReportExporter: React.FunctionComponent<IReportExporterProps> = (props) => {
    // const exportUrl = Report.getDownloadUrlForAllData(props.id);

    const exportPhrase = 'Export Data';

    if (props.exportEnable) {
        return (
            <div className="h-full align-middle ml-10 indicator">
                <span className="indicator-item badge badge-info">{props.counter}</span>
                <button className="btn btn-lg btn-primary" onClick={Report.getAllData}>
                    {exportPhrase}
                </button>
            </div>
        );
    } else {
        return (
            <div className="h-full align-middle ml-10 tooltip tooltip-warning tooltip-bottom indicator" data-tip="No groups configured to export!">
                <span className="indicator-item badge badge-warning">0</span>
                <button className="btn btn-lg btn-error hover:btn-error no-animation">{exportPhrase}</button>
            </div>
        );
    }
};

export default ReportExporter;
