
export interface IFileProgressModalProps {
    uploadFileName: string;
    currentProgress: number;
    maxProgress: number;
    open: boolean;
    onCloseModal: () => void;
}

const FileProgressModal: React.FunctionComponent<IFileProgressModalProps> = (props) => {
    let progressClasses = 'progress w-full progress-primary';
    let progressButton = 'btn btn-ghost btn-disabled';
    let progressText = 'Uploading';
    if (props.currentProgress < 0) {
        progressClasses = 'progress progress-error ';
        progressButton = 'btn btn-error';
        progressText = 'Upload Failed';
    } else if (props.currentProgress > 0 && props.currentProgress === props.maxProgress) {
        progressClasses = 'progress progress-primary w-full';
        progressButton = 'btn btn-success';
        progressText = 'Upload Done';
    }
    let progressModalState = 'modal';
    if (props.open) {
        progressModalState += ' modal-open';
    }

    if ((props.currentProgress > 0 && props.currentProgress === props.maxProgress) || props.currentProgress < 0) {
        // Show success or error
        return (
            <div className={progressModalState}>
                <div className="modal-box">
                    <div className="flex-row w-full">
                        <p className="font-semibold">File: {props.uploadFileName}</p>
                        <progress id="progress-bar" className={progressClasses} value={props.currentProgress} max={props.maxProgress}></progress>
                    </div>
                    <div className="modal-action">
                        <button id="progress-btn" className={progressButton} onClick={(e) => props.open && props.onCloseModal()}>
                            {progressText}
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        // show in progress
        return (
            <div className={progressModalState}>
                <div className="modal-box">
                    <div className="flex-row w-full">
                        <p className="font-semibold">File: {props.uploadFileName}</p>
                        <progress id="progress-bar" className={progressClasses}></progress>
                    </div>
                    <div className="modal-action">
                        <button id="progress-btn" className={progressButton} onClick={(e) => props.open && props.onCloseModal()}>
                            {progressText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
};

export default FileProgressModal;
