import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Report } from '../api/Report';
import FileUploader from '../components/FileUploader';
import GroupBuilder from '../components/GroupBuilder';
import PopulationChart from '../components/PopulationChart';
import ReportExporter from '../components/ReportExporter';
import ReportStats from '../components/ReportStats';

export interface IReportPageProps {}

export type ReportParams = {
};

const ReportPage: React.FunctionComponent<IReportPageProps> = (props) => {
    const [report, setReport] = useState(new Report({}));
    const [editGroup, setEditGroup] = useState('');

    const onReportUpdated = (report: Report) => {
        console.log('Received updated report:');
        console.log(report);

        setReport(report);
    };

    const onSelectGroup = (group: string) => {
        setEditGroup(group);
    };
    const onCreateGroup = (group: string) => {
        Report.createGroup(group).then((report) => {
            onReportUpdated(report);
        });
    };
    const onAddToGroup = (group: string, sample: string) => {
        console.log('Updating group with: ' + group + ' ' + sample);
        Report.addSampleToGroup(group, sample).then((report) => {
            onReportUpdated(report);
        });
    };
    const onDeleteFromGroup = (group: string, sample: string) => {
        console.log('Updating group with: ' + group + ' ' + sample);
        Report.removeSampleFromGroup(group, sample).then((report) => {
            onReportUpdated(report);
        });
    };
    const onDeleteGroup = (group: string) => {
        Report.deleteGroup(group).then((report) => {
            onReportUpdated(report);
        });
    };

    const getConfiguredGroupCount = (): number => {
        let validGroups = 0;

        Object.entries(report.groups).forEach(([group, members], key) => {
            if (members.length > 0) {
                validGroups += 1;
            }
        });

        return validGroups;
    };

    let nullHypoSelector: JSX.Element[] = [];
    if (getConfiguredGroupCount() > 0) {
    }

    let showCharts: JSX.Element[] = [];
    if (getConfiguredGroupCount() > 0) {
        showCharts.push(
            <div key={'chart'} className="flex flex-row justify-center">
                <PopulationChart report={report} />
            </div>
        );
    }

    return (
        <div className="flex flex-col overflow-hidden h-screen">
            <header>
                <div className="flex navbar bg-neutral-focus">
                    <div className="flex-1">
                        <Link to="/" className="text-base-100 font-bold normal-case text-xl px-5">
                            Bio Report
                        </Link>
                    </div>
                    <div className="flex-none">
                        <FileUploader onReportUpdated={onReportUpdated} />
                    </div>
                </div>
            </header>

            <main className="flex-1 w-full bg-base-content overflow-y-scroll scrollbar">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-center place-items-center">
                        <ReportStats fileName={report.filename} samples={report.samples.length} populations={report.populations.length} groups={getConfiguredGroupCount()} />
                        <ReportExporter counter={getConfiguredGroupCount()} exportEnable={getConfiguredGroupCount() > 0} />
                    </div>
                    <div className="flex flex-row justify-center">
                        <GroupBuilder
                            report={report}
                            selectGroup={onSelectGroup}
                            createGroup={onCreateGroup}
                            deleteGroup={onDeleteGroup}
                            addToGroup={onAddToGroup}
                            removeFromGroup={onDeleteFromGroup}
                            activeGroup={editGroup}
                        />
                    </div>
                    {nullHypoSelector}
                    {showCharts}
                </div>
            </main>

            <footer></footer>
        </div>
    );
};

export default ReportPage;
