export interface IFileSelectModal {
    open: boolean;
    onFileChosen: (file: File) => void;
    onCloseModal: () => void;
}

const FileSelectModal: React.FunctionComponent<IFileSelectModal> = (props) => {
    const onFileDropped = async (ev: any) => {
        console.log('On File dropped.');
        ev.stopPropagation();
        ev.preventDefault();

        if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
            const file = ev.dataTransfer.files[0];
            props.onFileChosen(file);
        }
    };
    const onFileSelected = async (ev: any) => {
        console.log('File selected.');
        if (ev.target.files && ev.target.files.length > 0) {
            const file = ev.target.files[0];
            props.onFileChosen(file);

            (document.getElementById('dropzone-file')! as HTMLInputElement).value = '';
        }
    };

    const onFileDragOver = (ev: any) => {
        ev.stopPropagation();
        ev.preventDefault();
    };

    let fileModalState = 'modal cursor-pointer';
    if (props.open) {
        fileModalState += ' modal-open';
    }

    return (
        <div className={fileModalState} onClick={(e) => props.open && props.onCloseModal()}>
            <div className="modal-box cursor-default" onClick={(e) => e.stopPropagation()}>
                <form id="file-upload">
                    <input value="" id="dropzone-file" accept=".csv, .xls, .wsp" type="file" className="hidden" hidden={true} multiple={false} onChange={onFileSelected} />
                    <label
                        className="flex-row w-full cursor-pointer"
                        htmlFor="dropzone-file"
                        onDrop={onFileDropped}
                        onDragOver={onFileDragOver}
                        onDragEnter={onFileDragOver}
                        onDragLeave={onFileDragOver}
                    >
                        <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-base-300 hover:border-primary-content border-dashed rounded-lg cursor-pointer bg-base-100 hover:bg-base-300">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 ">
                                    <span className="font-semibold ">Click to upload</span> or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400 ">File Type: .CSV or WSP (MAX 16 MB)</p>
                            </div>
                        </div>
                    </label>
                    <div className="modal-action">
                        <button className="btn btn-accent" onClick={(e) => props.onCloseModal()}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FileSelectModal;
