
export interface IReportStatsProps {
    fileName: string;
    samples: number;
    groups: number;
    populations: number;
}

const ReportStats: React.FunctionComponent<IReportStatsProps> = (props) => {
    let groupStat: JSX.Element;
    if (props.groups <= 0) {
        groupStat = (
            <div className="stat place-items-center">
                <div className="stat-title font-semibold select-none">Configured Groups</div>
                <div className="stat-value text-accent">{props.groups}</div>
            </div>
        );
    } else {
        groupStat = (
            <div className="stat place-items-center">
                <div className="stat-title font-semibold select-none">Configured Groups</div>
                <div className="stat-value text-primary">{props.groups}</div>
            </div>
        );
    }

    let shownFileName = props.fileName;
    if (shownFileName === '') {
        shownFileName = 'Waiting for Upload.';
    }

    return (
        <div className="stats bg-neutral-content shadow-xl m-3">
            <div className="stat">
                <div className="stat-title font-semibold select-none">Source File</div>
                <div className="stat-value text-primary-content text-lg">{props.fileName}</div>
            </div>

            <div className="stat place-items-center">
                <div className="stat-title font-semibold select-none">Samples</div>
                <div className="stat-value text-secondary">{props.samples}</div>
            </div>

            <div className="stat place-items-center">
                <div className="stat-title font-semibold select-none">Gated Populations</div>
                <div className="stat-value text-secondary">{props.populations}</div>
            </div>

            {groupStat}
        </div>
    );
};

export default ReportStats;
