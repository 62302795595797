import React, { useState } from 'react';
import { Report } from '../api/Report';

export interface IGroupBuilderProps {
    activeGroup: string;
    report: Report;

    selectGroup: (groupName: string) => void;
    createGroup: (groupName: string) => void;
    deleteGroup: (groupName: string) => void;
    addToGroup: (groupName: string, sample: string) => void;
    removeFromGroup: (groupName: string, sample: string) => void;
}

const GroupBuilder: React.FunctionComponent<IGroupBuilderProps> = (props) => {
    const [newGroupName, setNewGroupName] = useState('');

    const isGroupSample = (selectGroup: string, testSample: string): boolean => {
        let found = false;

        Object.entries(props.report.groups).forEach(([group, members], key) => {
            if (selectGroup === group) {
                for (const idx in members) {
                    const sample = members[idx];
                    if (sample === testSample) {
                        found = true;
                        break;
                    }
                }
            }
        });
        return found;
    };
    const getGroupCount = (): number => {
        let validGroups = 0;

        Object.entries(props.report.groups).forEach(([group, members], key) => {
            validGroups += 1;
        });

        return validGroups;
    };

    let sampleOptions: JSX.Element[] = [];
    const samples = props.report.samples.sort();
    for (let idx in samples) {
        let sampleName = samples[idx];

        if (isGroupSample(props.activeGroup, sampleName)) {
            sampleOptions.push(<option selected>{sampleName}</option>);
        } else {
            sampleOptions.push(<option>{sampleName}</option>);
        }
    }

    let groupButtons: JSX.Element[] = [];
    for (let idx in props.report.group_order) {
        const group = props.report.group_order[idx];
        if (props.activeGroup === group) {
            groupButtons.push(
                <button key={group} className="btn btn-success m-1 border-2 hover:border-2 border-black hover:border-black btn-sm">
                    {group}
                </button>
            );
        } else {
            groupButtons.push(
                <button
                    key={group}
                    className="btn btn-info hover:btn-success m-1 border border-black btn-sm"
                    onClick={(e) => {
                        props.selectGroup(group);
                    }}
                >
                    {group}
                </button>
            );
        }
    }

    let populationSelector: JSX.Element;
    if (props.activeGroup !== '') {
        let populationButtons: JSX.Element[] = [];

        for (const idx in props.report.samples) {
            const sample = props.report.samples[idx];
            if (isGroupSample(props.activeGroup, sample)) {
                populationButtons.push(
                    <button key={sample} className="btn btn-xs m-1 btn-success border border-black" onClick={(e) => props.removeFromGroup(props.activeGroup, sample)}>
                        {sample}
                    </button>
                );
            } else {
                populationButtons.push(
                    <button key={sample} className="btn btn-xs m-1 btn-error" onClick={(e) => props.addToGroup(props.activeGroup, sample)}>
                        {sample}
                    </button>
                );
            }
        }

        populationSelector = (
            <label className="flex flex-col w-96 m-2 text-left">
                <span className="pr-4 text-primary-content font-semibold">Select Samples for {props.activeGroup}:</span>
                <div>{populationButtons}</div>
            </label>
        );
    } else {
        const groupCount = getGroupCount();
        if (groupCount === 0) {
            populationSelector = (
                <label className="w-96 m-2">
                    <span className="text-primary-content font-semibold">Create a group before setting samples.</span>
                </label>
            );
        } else {
            populationSelector = <label className="w-96 m-2 text-center text-primary-content font-semibold align-middle">Select a Group to modify samples.</label>;
        }
    }

    return (
        <div className="flex flex-row rounded-box bg-base-100">
            <div className="m-2 w-96 h-32 rounded-box place-items-top">
                <div className="input-group w-full">
                    <input
                        autoFocus
                        type="text"
                        placeholder="New Group name..."
                        value={newGroupName}
                        onChange={(ev) => {
                            setNewGroupName(ev.currentTarget.value);
                        }}
                        onKeyUp={(ev) => {
                            if (ev.key === 'Enter' || ev.keyCode === 13) {
                                setNewGroupName('');
                                props.createGroup(newGroupName);
                            }
                        }}
                        className="input input-bordered w-64"
                    />
                    <button
                        className="btn btn-square btn-success w-32"
                        onClick={(e) => {
                            setNewGroupName('');
                            props.createGroup(newGroupName);
                        }}
                    >
                        Create
                    </button>
                </div>
                <div className="py-2">{groupButtons}</div>
            </div>

            <div className="divider divider-horizontal"></div>

            {populationSelector}
        </div>
    );
};

export default GroupBuilder;
