import React, { useState } from 'react';
import { Report } from '../api/Report';
import FileProgressModal from './FileProgressModal';
import FileSelectModal from './FileSelectModal';

export interface IFileUploaderProps {
    onReportUpdated: (report: Report) => void;
}

const FileUploader: React.FunctionComponent<IFileUploaderProps> = (props) => {
    const [uploadFileName, setUploadFileName] = useState('');
    const [currentProgress, setCurrentProgress] = useState(0);
    const [maxProgress, setMaxProgress] = useState(0);
    const [fileModalOpen, setFileModalOpen] = useState(false);
    const [progressModalOpen, setProgressModalOpen] = useState(false);

    const onProgressUpdates = (current: number, total: number) => {
        setCurrentProgress(current);
        setMaxProgress(total);
    };

    const onFileChosen = async (file: File) => {
        var filename = file.name.toLowerCase();
        if (filename.endsWith('csv') || filename.endsWith('wsp')) {
            setUploadFileName(file.name);
            setFileModalOpen(false);
            setProgressModalOpen(true);

            try {
                const report = await Report.uploadFileToReport(file, onProgressUpdates);
    
                if (report) {
                    props.onReportUpdated(report);
                }
            } catch {
                // TODO: Show an error dialog instead
                console.log("File failed to load...");
            }
        } else {
            console.log('Rejected bad file: ' + filename);
        }
    };

    return (
        <div>
            <label className="btn btn-secondary" onClick={(e) => setFileModalOpen(true)}>
                Upload Data
            </label>
            <FileSelectModal
                open={fileModalOpen}
                onFileChosen={onFileChosen}
                onCloseModal={() => {
                    setFileModalOpen(false);
                }}
            />
            <FileProgressModal
                uploadFileName={uploadFileName}
                currentProgress={currentProgress}
                maxProgress={maxProgress}
                open={progressModalOpen}
                onCloseModal={() => {
                    setProgressModalOpen(false);
                }}
            />
        </div>
    );
};

export default FileUploader;
