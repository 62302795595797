import React, { useEffect, useState } from 'react';
import { Report, PopulationStatistics } from '../api/Report';

import Chart from 'react-apexcharts';

export interface IPopulationChartProps {
    report: Report;
}

const PopulationChart: React.FunctionComponent<IPopulationChartProps> = (props) => {
    const [population, setPopulation] = useState('');
    const [chartSeriesData, setChartSeriesData] = useState({} as PopulationStatistics);

    useEffect(() => {
        const sortedPopulations = props.report.populations.sort();
        setPopulation(sortedPopulations[0]);
    }, [props.report]);
    useEffect(() => {
        Report.getChartData(population).then((chartData) => {
            console.log('Received chart Data: ');
            console.log(chartData);
            setChartSeriesData(chartData);
        });
    }, [props.report, population]);

    if (chartSeriesData.statistics) {
        const seriesKeys = Object.keys(chartSeriesData.statistics);

        let pvalues = [];
        for (let idx in props.report.group_order) {
            const group = props.report.group_order[idx];
            if (seriesKeys.includes(group)) {
                var pvalue = '';

                if (group !== 'PBS') {
                    if ('PBS' in chartSeriesData.pvalues) {
                        pvalue = 'P-Value: ' + Math.round(chartSeriesData.pvalues['PBS'][group] * 10000) / 10000.0;
                    }
                }

                let obj = {
                    x: group,
                    borderColor: '#00E39600',
                    label: {
                        borderColor: '#00E39600',
                        orientation: 'horizontal',
                        text: pvalue
                    }
                };
                pvalues.push(obj);
            }
        }

        const options = {
            chart: {
                zoom: {
                    enabled: false
                }
            },
            title: {
                text: `${population}`,
                align: 'left'
            } as ApexTitleSubtitle,
            plotOptions: {
                boxPlot: {
                    colors: {
                        upper: '#5C4742',
                        lower: '#A5978B'
                    }
                }
            },
            tooltip: {
                shared: false,
                intersect: true
            },
            annotations: {
                xaxis: pvalues
            }
        };

        let boxData = {
            type: 'boxPlot',
            data: [] as any
        };

        for (let idx in props.report.group_order) {
            const group = props.report.group_order[idx];
            if (seriesKeys.includes(group)) {
                let yValues = chartSeriesData.statistics[group];
                if (yValues == null) {
                    yValues = [];
                }
                let obj = {
                    x: group,
                    y: yValues.map(function(y) { return Math.round(y*100*100)/100;})
                };
                boxData.data.push(obj);
            }
        }

        const series = [boxData];

        const populationOptions: JSX.Element[] = [];

        const sortedPopulations = props.report.populations.sort();
        for (let idx in sortedPopulations) {
            const population = sortedPopulations[idx];
            populationOptions.push(<option key={population}>{population}</option>);
        }

        return (
            <div className="flex flex-col m-5 p-2 rounded-box bg-white">
                <div className="flex flex-row-reverse">
                    <select
                        value={population}
                        className="select select-ghost w-full max-w-xs"
                        onChange={(ev) => {
                            setPopulation(ev.target.value);
                        }}
                    >
                        <option disabled>View BoxPlot for Population</option>
                        {populationOptions}
                    </select>
                </div>
                <Chart options={options} series={series} type={'boxPlot'} height={500} width={900} />
            </div>
        );
    } else {
        return (
            <div className="m-5 p-2 rounded-box bg-white">
                <h1>Waiting for Groups to be setup.</h1>
            </div>
        );
    }
};

export default PopulationChart;
